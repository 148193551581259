.mbsc-ios.mbsc-popup-body-round {
    padding: 24px;
}

.mbsc-font,
.mbsc-ios.mbsc-popup-body,
.mbsc-ios.mbsc-calendar,
.mbsc-ios.mbsc-segmented-button.mbsc-button,
.mbsc-ios.mbsc-calendar-cell {
    color: rgb(10, 24, 43);
}

.mbsc-ios.mbsc-popup-header-center,
.mbsc-ios.mbsc-popup-header-no-buttons {
    border: 0;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    padding: 0px;
    position: relative;
    text-align: left;
}

.mbsc-ios.mbsc-popup-button-primary {
    align-items: center;
    background: #1e6cf8 !important;
    border: none !important;
    border-radius: 8px !important;
    color: #fff !important;
    display: flex;
    flex: initial !important;
    flex-direction: row;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 24px !important;
    width: 56px;
}

.mbsc-ios.mbsc-popup-buttons {
    display: flex !important;
    justify-content: space-between;
}

.mbsc-ios.mbsc-popup-button-close.mbsc-ltr {
    background: #f5f6f9 !important;
    border: none !important;
    border-radius: 8px !important;
    color: #1e6cf8;
    display: flex !important;
    flex: initial;
    font-weight: 400;
    justify-content: space-around;
    width: 81px;
}

.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    background: #1e6cf8;
    border-color: #1e6cf8;
    color: #fff;
}

.mbsc-ios.mbsc-range-day:after {
    background-color: #1e6cf8;
}

.mbsc-ios.mbsc-range-day .mbsc-calendar-cell-text {
    color: #fff;
}

.mbsc-ios.mbsc-calendar-button.mbsc-button {
    color: #1e6cf8;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.mbsc-ios.mbsc-range-label-clear {
    color: #1e6cf8;
}

.mbsc-ios.mbsc-segmented {
    background: #f5f6f9;
}

.mbsc-ios.mbsc-range-control-text-empty,
.mbsc-ios.mbsc-range-control-text-empty.active,
.mbsc-ios.mbsc-range-control-value.active {
    color: #6c747f;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.mbsc-ios.mbsc-range-control-label.active,
.mbsc-ios.mbsc-range-control-value.active {
    font-weight: 400;
}
